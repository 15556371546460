import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import api from '../../../repositories/api';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { cloneDirector } from '../../../store/modules/user/actions';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  TextField,
  CircularProgress,
  FormControl
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const CloneDirectorModal = ({ handleClose, visible }) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem('@Matrix:token');

  const [originalOpen, setOriginalOpen] = useState(false);
  const [originalQuery, setOriginalQuery] = useState('');
  const [originalOptions, setOriginalOptions] = useState([]);
  const originalLoading = originalOpen && originalOptions.length === 0;

  const [clonedOpen, setClonedOpen] = useState(false);
  const [clonedQuery, setClonedQuery] = useState('');
  const [clonedOptions, setClonedOptions] = useState([]);
  const clonedLoading = clonedOpen && clonedOptions.length === 0;

  const [originalUserId, setOriginalUserId] = useState(null);
  const [clonedUserId, setClonedUserId] = useState(null);

  const fetchOptions = async (query, setOptions) => {
    try {
      const response = await api.axios.get('/user', {
        params: {
          search: query || undefined,
          limit: 10,
          fields: 'name,_id',
          sort: '-createdAt',
        },
        headers: { Authorization: `Bearer ${token}` },
      });
      setOptions(response.data.items);
    } catch (err) {
      toast.error('Não foi possível obter os usuários.');
    }
  };

  const handleCloneUser = () => {
    if (originalUserId && clonedUserId) {
      dispatch(cloneDirector(originalUserId, clonedUserId, token));

      handleClose()
    } else {
      toast.error('Confira todos os campos.');
    }
  };

  useEffect(() => {
    fetchOptions(originalQuery, setOriginalOptions);
  }, [originalQuery, originalOpen]);

  useEffect(() => {
    fetchOptions(clonedQuery, setClonedOptions);
  }, [clonedQuery, clonedOpen]);

  return (
    <Dialog open={visible} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography>Clonar diretor</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                open={originalOpen}
                onOpen={() => setOriginalOpen(true)}
                onClose={() => setOriginalOpen(false)}
                onChange={(_, value) => setOriginalUserId(value?._id || null)}
                getOptionLabel={(option) => option.name.complete}
                options={originalOptions}
                loading={originalLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Diretor original"
                    value={originalQuery}
                    onChange={(e) => setOriginalQuery(e.target.value)}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {originalLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                open={clonedOpen}
                onOpen={() => setClonedOpen(true)}
                onClose={() => setClonedOpen(false)}
                onChange={(_, value) => setClonedUserId(value?._id || null)}
                getOptionLabel={(option) => option.name.complete}
                options={clonedOptions}
                loading={clonedLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Diretor clonado"
                    value={clonedQuery}
                    onChange={(e) => setClonedQuery(e.target.value)}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {clonedLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Button
              style={{ height: '100%' }}
              variant="contained"
              color="primary"
              onClick={handleCloneUser}
            >
              CLONAR
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">Fechar</Button>
      </DialogActions>
    </Dialog>
  );
};

CloneDirectorModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CloneDirectorModal;
