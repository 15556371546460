import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

import { formatPrice } from '../../../../../utils/format'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tableRow: {
    cursor: 'pointer'
  }
}))

export default function GoalsTable({
  className,
  goals,
  onChangeRows,
  onChangePage,
  metadata,
  handleSelectGoal,
  ...rest
}) {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '2rem' }} />
                  <TableCell>Mês</TableCell>
                  <TableCell>Meta da Loja</TableCell>
                  <TableCell>Meta de Ticket</TableCell>
                  <TableCell>Meta de Preço Médio</TableCell>
                  <TableCell>Quantidade de Vendedores</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {goals && goals.length > 0 ? (
                  goals.map((goal, i) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={i}
                      selected={false}
                      onClick={() => handleSelectGoal(goal._id)}
                    >
                      <TableCell align='center'><EditIcon style={{ color: '#63727A' }} /></TableCell>
                      <TableCell>
                        <div className={classes.nameContainer}>
                          <Typography variant='body1'>
                            {moment(goal.month).format('MMMM/YYYY')}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell>{formatPrice(goal.mainGoals.total)}</TableCell>
                      <TableCell>
                        {formatPrice(goal.mainGoals.ticket)}
                      </TableCell>
                      <TableCell>{formatPrice(goal.mainGoals.price)}</TableCell>
                      <TableCell>{goal.usersWorking.length}</TableCell>
                    </TableRow>
                  ))
                ) : (
                    <TableRow>
                      <TableCell colSpan='6' style={{ textAlign: 'center' }}>
                        {goals ? 'Sem metas cadastradas.' : 'Carregando...'}
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component='div'
          count={metadata ? metadata.pagination.totalCount : 0}
          onPageChange={(event, page) => onChangePage(page)}
          onRowsPerPageChange={(event, rows) => onChangeRows(rows)}
          page={metadata ? metadata.pagination.currentPage - 1 : 0}
          rowsPerPage={metadata.pagination.limit}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      </CardActions>
    </Card>
  )
}

GoalsTable.propTypes = {
  className: PropTypes.string,
  goals: PropTypes.array,
  onChangeRows: PropTypes.func,
  onChangePage: PropTypes.func,
  metadata: PropTypes.object
}
