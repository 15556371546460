import React, { useEffect, useState } from 'react';

// Libs
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import api from '../../../../repositories/api';
import { toast } from 'react-toastify'

import {
  linkUser,
} from '../../../../store/modules/store/actions';


// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { useConfirm } from 'material-ui-confirm';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

// Styles
const useStyles = makeStyles((theme) => ({
  root: {},
  margin: {
    margin: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
    marginLeft: '16px',
  },
  title: {
    marginBottom: '16px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginBottom: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  size: {
    minWidth: '180px',
    marginBottom: theme.spacing(1),
    margin: theme.spacing(1),
  },
}));


const LinkUserModal = ({
  handleClose,
  visible,
  store,
  userList,
  handleSearch,
  searchList,
  handleUpdateUser,
  handleUnlinkUser,
  handleLinkUserAction,
  ...rest
}) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const dispatch = useDispatch()

  const token = localStorage.getItem('@Matrix:token')

  // AutoComplete
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState();
  const [options, setOptions] = useState([]);
  const searchLoading = open && options.length === 0;

  const DEFAULT_LINKUSERDATA = {
    userId: null,
    type: null,
    code: null,
  };

  const [linkUserData, setLinkUserData] = useState(DEFAULT_LINKUSERDATA);

  const loadOptions = async () => {
    const response = await api.axios.get(`/user`, {
      params: {
        search: query,
        limit: 10,
        fields: `name,_id`,
        sort: '-createdAt',
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    setOptions(response.data.items.filter(user => !store.users.map(u => u._id).includes(user._id)));
  };

  useEffect(() => {
    console.log(options.filter(user => !store.users.map(u => u._id).includes(user._id)))
  }, [options])

  function handleLinkUser() {
    if (!linkUserData.userId || !linkUserData.type) {
      toast.error('Confira todos os campos.');
    } else {
      const userLinkBody = {
        storeId: store._id,
        type: linkUserData.type
      }
        if(linkUserData.type === 'seller') userLinkBody.code = linkUserData.code;
      dispatch(
        linkUser(
          userLinkBody,
          linkUserData.userId,
          token
        )
      );
    }
  }

  useEffect(() => {
    loadOptions();
  }, [query, store]);

  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography>
          Vincular usuário
          </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={linkUserData.type === 'seller' ? 3 : 6}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onChange={(e, value) =>
                  setLinkUserData({
                    ...linkUserData,
                    userId: value._id,
                  })
                }
                getOptionSelected={(option, value) => option === value}
                getOptionLabel={(option) => option.name.complete}
                options={options}
                loading={searchLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Pesquisar"
                    value={query}
                    onChange={(event) => setQuery(event.target.value)}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {searchLoading ? (
                            <CircularProgress
                              color="inherit"
                              size={20}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Cargo</InputLabel>
              <Select
                fullWidth
                onChange={(event) =>
                  setLinkUserData({
                    ...linkUserData,
                    type: event.target.value,
                  })
                }
                value={linkUserData.type || ''}
                labelWidth={35}
              >
                <MenuItem value="owner">Dono</MenuItem>
                <MenuItem value="director">Diretor</MenuItem>
                <MenuItem value="manager">Gerente</MenuItem>
                <MenuItem value="seller">Vendedor</MenuItem>
                <MenuItem value="cashier">Caixa</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {linkUserData.type === 'seller' ? (
            <Grid item xs={3}>
              <FormControl variant="outlined">
                <InputLabel>Código de segurança</InputLabel>
                <OutlinedInput
                  onChange={(event) =>
                    setLinkUserData({
                      ...linkUserData,
                      code: event.target.value,
                    })
                  }
                  value={linkUserData.code || ''}
                  labelWidth={135}
                />
              </FormControl>
            </Grid>
          ) : (
              <></>
            )}
          <Grid item xs={3}>
            <Button
              style={{ height: '100%' }}
              variant="contained"
              color="primary"
              onClick={() => handleLinkUser()}
            >
              VINCULAR
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Fechar
          </Button>
      </DialogActions>
    </Dialog>
  );
}

LinkUserModal.propTypes = {
  visible: PropTypes.bool,
  handleClose: PropTypes.func,
  handleUpdateUser: PropTypes.func,
  handleUnlinkUser: PropTypes.func,
  user: PropTypes.object,
}

export default LinkUserModal
