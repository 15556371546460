/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// Libs
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";

// Redux
import { storeListRequest, storeAddRequest, storeRequest } from "../../store/modules/store/actions";
import { monthRequest } from "../../store/modules/report/actions";
import { companyListRequest } from "../../store/modules/company/actions";

// Components
import { StoresTable, AddStoreModal } from "./components";
import { Toolbar } from "../../components";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const StoreList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory()

  const [openModal, setOpenModal] = useState(false);

  const token = localStorage.getItem("@Matrix:token");

  const storeList = useSelector(state => state.store.storeList);
  const companyList = useSelector(state => state.company.companyList);
  const loading = useSelector(state => state.store.loading);
  const metadata = useSelector(state => state.store.metadata)

  useEffect(() => {
    if (metadata.pagination.totalCount < 0) onRefresh();
  }, []);

  useEffect(() => {
    setOpenModal(false);
  }, [storeList]);

  const onRefresh = () => {
    dispatch(storeListRequest(localStorage.getItem("@Matrix:token"), metadata.pagination.currentPage, metadata.pagination.limit));
    dispatch(companyListRequest(localStorage.getItem("@Matrix:token")));
  }

  const handleChangePage = (page) => {
    if (page + 1 <= metadata.pagination.pageCount) {
      dispatch(storeListRequest(
        localStorage.getItem("@Matrix:token"),
        page + 1,
        metadata.pagination.limit
      ));
    }
  }

  const handleChangeRows = (rowsNumber) => {
    dispatch(storeListRequest(
      localStorage.getItem("@Matrix:token"),
      metadata.pagination.currentPage,
      Number(rowsNumber.props.value),
    ));
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Toolbar
          onAdd={() => setOpenModal(true)}
          actionText="Adicionar Loja"
          onRefresh={onRefresh}
        />
        <StoresTable
          onChangeRows={handleChangeRows}
          onChangePage={handleChangePage}
          metadata={metadata}
          stores={storeList}
          handleOpenModal={(storeId) => {
            history.push(`/store/${storeId}`)
            dispatch(storeRequest(storeId, token))
            dispatch(monthRequest(storeId, localStorage.getItem('@Matrix:token')))
          }}
        />
        <AddStoreModal
          companies={companyList}
          loading={loading}
          active={openModal}
          onClose={() => setOpenModal(false)}
          addStore={store => dispatch(storeAddRequest({ ...store }, token))}
        />
      </div>
    </div>
  );
}

export default StoreList
