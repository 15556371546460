export function userRequest(userId, token, editUser) {
  return {
    type: '@user/USER_REQUEST',
    userId,
    token,
    editUser,
  };
}

export function userSuccess(dataRequest) {
  return {
    type: '@user/USER_SUCCESS',
    dataRequest,
  };
}

export function userEditRequest(userId, token) {
  return {
    type: '@user/EDIT_REQUEST',
    userId,
    token,
  };
}

export function userEditSuccess(dataRequest) {
  return {
    type: '@user/EDIT_SUCCESS',
    dataRequest,
  };
}

export function userEditSuccessT(dataRequest) {
  return {
    type: '@user/EDIT_SUCCESST',
    dataRequest,
  };
}

export function userAvatarUpdateRequest(dataRequest, userId, token) {
  return {
    type: '@user/AVATAR_UPDATE_REQUEST',
    payload: { dataRequest, userId, token }
  }
}

export function userAvatarUpdateSuccess(userId, url) {
  return {
    type: '@user/AVATAR_UPDATE_SUCCESS',
    payload: { url, userId }
  }
}

export function userFailed(dataRequest) {
  return {
    type: '@user/USER_FAILED',
    dataRequest,
  };
}

export function userListRequest(token, page = 1, limit = 10) {
  return {
    type: '@user/USER_LIST_REQUEST',
    token,
    page,
    limit,
  };
}

export function userListSuccess(dataRequest) {
  return {
    type: '@user/USER_LIST_SUCCESS',
    dataRequest,
  };
}

export function userAddRequest(user, token) {
  return {
    type: '@user/ADD_USER_REQUEST',
    user,
    token,
  };
}

export function userAddSuccess(dataRequest) {
  return {
    type: '@user/USER_ADD_SUCCESS',
    dataRequest,
  };
}

export function userUpdateRequest(userData, userId, token) {
  return {
    type: '@user/UPDATE_REQUEST',
    userData,
    userId,
    token,
  };
}

export function handleOpenModal() {
  return {
    type: '@modal/OPEN',
  };
}

export function handleCloseModal() {
  return {
    type: '@modal/CLOSE',
  };
}

export function cloneDirector(originalDirector, clonedDirector, token) {
  return {
    type: '@user/CLONE_DIRECTOR_REQUEST',
    originalDirector,
    clonedDirector,
    token,
  };
}
