import { call, put, all, takeLatest } from 'redux-saga/effects';
import api from '../../../repositories/api';

import { toast } from 'react-toastify';

import {
  userSuccess,
  userEditSuccess,
  userEditSuccessT,
  userListSuccess,
  userAddSuccess,
  userAvatarUpdateSuccess
} from './actions';

import { requestFailed } from '../error/action';

export function* user({ userId, token, editUser }) {
  try {
    const response = yield call(api.axios.get, `/user/${userId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (editUser) {
      return yield put(userEditSuccessT(response.data));
    }
    yield put(userSuccess(response.data));
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Servidor fora do ar.',
          },
        })
      );
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
              error: {
                friendlyMsg: 'Alguém deve ter desligado nosso servidor...',
              },
            }
        )
      );
    }
  }
}

export function* userList({ token, page, limit }) {
  try {
    const response = yield call(
      api.axios.get,
      `/user/?limit=${limit}&page=${page}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    yield put(userListSuccess(response.data));
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Servidor fora do ar.',
          },
        })
      );
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
              error: {
                friendlyMsg: 'Alguém deve ter desligado nosso servidor...',
              },
            }
        )
      );
    }
  }
}

export function* userAdd({ user, token }) {
  try {
    // yield put(RepositoriesActions.send())
    const response = yield call(api.axios.post, `/user`, user, {
      headers: { Authorization: `Bearer ${token}` },
    });
    yield put(userAddSuccess(response.data));
    toast.success('Usuario adicionado com sucesso!');
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Servidor fora do ar.',
          },
        })
      );
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
              error: {
                friendlyMsg: 'Alguém deve ter desligado nosso servidor...',
              },
            }
        )
      );
    }
  }
}

export function* userUpdate({ userData, userId, token }) {
  try {
    // yield put(RepositoriesActions.send())
    const response = yield call(api.axios.put, `/user/${userId}`, userData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    yield put(userEditSuccess(response.data));
    toast.success('Usuario alterado com sucesso!');
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Servidor fora do ar.',
          },
        })
      );
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
              error: {
                friendlyMsg: 'Alguém deve ter desligado nosso servidor...',
              },
            }
        )
      );
    }
  }
}

export function* userAvatarUpdate({ payload }) {
  try {
    const { dataRequest, token, userId } = payload
    const response = yield call(api.axios.post, `/user/${userId}/upload`, dataRequest, {
      headers: { Authorization: `Bearer ${token}` }
    })

    toast.success('Avatar atualizado com sucesso.')
    yield put(userAvatarUpdateSuccess(userId, response.data.url))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
          )
        )
      }
    }
  }
}

export function* cloneDirector(payload) {
  const { originalDirector, clonedDirector, token } = payload

  try {
    yield call(api.axios.post, `/group/clone-director-groups`, {
      originalDirector,
      clonedDirector,
    }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    yield call(api.axios.get, `/group/sync-directors`, {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 360_000
    });
    toast.success('Diretor clonado com sucesso!');
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Servidor fora do ar.',
          },
        })
      );
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
              error: {
                friendlyMsg: 'Alguém deve ter desligado nosso servidor...',
              },
            }
        )
      );
    }
  }
}

export default all([
  takeLatest('@user/USER_REQUEST', user),
  takeLatest('@user/USER_LIST_REQUEST', userList),
  takeLatest('@user/ADD_USER_REQUEST', userAdd),
  takeLatest('@user/UPDATE_REQUEST', userUpdate),
  takeLatest('@user/AVATAR_UPDATE_REQUEST', userAvatarUpdate),
  takeLatest('@user/CLONE_DIRECTOR_REQUEST', cloneDirector),
]);
