import React, { useEffect, useState } from 'react';

// Libs
import PropTypes from 'prop-types'

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { useConfirm } from 'material-ui-confirm';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

// Styles
const useStyles = makeStyles((theme) => ({
  root: {},
  margin: {
    margin: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
    marginLeft: '16px',
  },
  title: {
    marginBottom: '16px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginBottom: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  size: {
    minWidth: '180px',
    marginBottom: theme.spacing(1),
    margin: theme.spacing(1),
  },
}));

const EditUserModal = ({
  visible,
  handleClose,
  handleUpdateUser,
  handleUnlinkUser,
  user
}) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const [userData, setUserData] = useState({
    code: '',
    type: ''
  })

  useEffect(() => {
    if (user) {
      setUserData(user)
    }
  }, [user])

  return (
    <div>
      <Dialog
        open={visible}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>
            {user ? user.name.complete : ''} -{' '}

            {
              {
                cashier: 'Caixa',
                director: 'Diretor',
                manager: 'Gerente',
                owner: 'Dono',
                seller: 'Vendedor',
              }[user ? user.type : '']
            }
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs>
              <FormControl
                fullWidth
                className={classes.formControl}
                variant="outlined"
              >
                <InputLabel>Código de Segurança</InputLabel>
                <OutlinedInput
                  onChange={(event) => {
                    event.persist()
                    console.log(event.target.value)
                    setUserData(oldData => ({
                      ...oldData,
                      code: event.target.value
                    }));
                  }}
                  value={userData.code}
                  labelWidth={135}
                  inputProps={{
                    maxLength: 4
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl
                fullWidth
                className={classes.formControl}
                variant="outlined"
              >
                <InputLabel>Cargo</InputLabel>
                <Select
                  id="type"
                  name="type"
                  onChange={(event) => {
                    setUserData(oldData => ({
                      ...oldData,
                      type: event.target.value
                    }));
                  }}
                  value={userData.type}
                  labelWidth={35}
                >
                  <MenuItem value="owner">Dono</MenuItem>
                  <MenuItem value="director">Diretor</MenuItem>
                  <MenuItem value="manager">Gerente</MenuItem>
                  <MenuItem value="seller">Vendedor</MenuItem>
                  <MenuItem value="cashier">Caixa</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <IconButton
              className={classes.button}
              onClick={() => handleUpdateUser({
                storeId: user.storeId,
                code: userData.code,
                type: userData.type,
              }, user.userId)}
              color="primary"
              component="span"
            >
              <SaveIcon className={classes.icon} />
            </IconButton>
            <IconButton
              className={classes.button}
              onClick={() => {
                confirm({
                  title: 'Desvincular usuário?',
                  description: `Ao confirmar você irá desvincular o usuário ${user.name.complete} da loja, podendo impactar diretamente nos indicadores.`,
                  confirmationText:
                    'Sei o que estou fazendo. Me solta!',
                  cancellationText: 'Ops, errei.',
                })
                  .then(() => handleUnlinkUser({
                    storeId: user.storeId._id,
                    userId: user.userId
                  }))
                  .catch((err) => console.log(err));
              }}
              color="primary"
              component="span"
            >
              <LinkOffIcon />
            </IconButton>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditUserModal.propTypes = {
  visible: PropTypes.bool,
  handleClose: PropTypes.func,
  handleUpdateUser: PropTypes.func,
  handleUnlinkUser: PropTypes.func,
  user: PropTypes.object,
}

export default EditUserModal
