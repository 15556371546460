import React, { useState, useEffect } from 'react';

// Libs
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form'
import moment from 'moment';

// Material UI
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useConfirm } from 'material-ui-confirm';

// Redux
import { userUpdateRequest } from '../../../store/modules/user/actions';

// Utils
import { cpfMask, phoneMask } from '../../../utils/inputMasks'


const useStyles = makeStyles(theme => ({
  root: {},
  margin: {
    margin: theme.spacing(1)
  },
  buttonSuccess: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));

export default function AddUserModal({
  active,
  onClose,
  user,
  loading,
  token,
  ...rest
}) {

  const { register, handleSubmit, control } = useForm()
  const dispatch = useDispatch();
  const classes = useStyles();
  const confirm = useConfirm()


  const defaultValue = {
    name: {
      complete: '',
      first: '',
      last: ''
    },
    birthDate: '20-05-2020',
    phone: '',
    email: '',
  }

  const [userData, setUserData] = useState(defaultValue);
  const [inititalData, setInitialData] = useState();
  const [password, setPassword] = useState('')
  const [date, setDate] = useState()

  useEffect(() => {
    if (user) {
      setUserData({
        name: {
          complete: user.name.complete,
          first: user.name.first,
          last: user.name.last,
        },
        birthDate: user.birthDate ? user.birthDate : new Date(),
        phone: user.phone,
        email: user.email,
        cpf: user.cpf ? user.cpf : ''
      });
      setInitialData({
        name: {
          complete: user.name.complete,
          first: user.name.first,
          last: user.name.last,
        },
        birthDate: user.birthDate ? user.birthDate : new Date(),
        phone: user.phone,
        email: user.email,
        cpf: user.cpf ? user.cpf : ''
      });
      setDate(user.birthDate ? user.birthDate : new Date())
    }
  }, [user]);

  const onSubmit = submitData => {
    const data = {}

    if (submitData.firstName !== inititalData.name.first) {
      data.name = {
        complete: submitData.firstName + ' ' + submitData.lastName,
        first: submitData.firstName,
        last: submitData.lastName,
      }
    }

    if (date !== inititalData.birthDate) data.birthDate = date
    if (submitData.phone.replace(/[^a-zA-Z0-9]/g, "",) !== inititalData.phone) data.phone = submitData.phone.replace(/[^a-zA-Z0-9]/g, "",)
    if (submitData.email !== inititalData.email) data.email = submitData.email
    if (submitData.password.length > 0) data.password = password
    if (submitData.cpf.replace(/[^a-zA-Z0-9]/g, "",) !== inititalData.cpf) data.cpf = submitData.cpf.replace(/[^a-zA-Z0-9]/g, "",)

    if (data.password) {
      confirm({
        title: 'Atenção!',
        description: `Tem certeza que deseja atualizar a senha do usuário? Essa ação não pode ser desfeita.`,
        confirmationText:
          'Sei o que estou fazendo. Me solta!',
        cancellationText: 'Ops, errei.',
      })
        .then(() => dispatch(userUpdateRequest(data, user._id, token)))
        .catch((err) => console.log(err));
    } else {
      dispatch(userUpdateRequest(data, user._id, token));
      onClose();
    }

  }


  return (
    <div {...rest} className={classes.root}>
      <Dialog
        open={active}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <form
          className={classes.root}
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogTitle id="form-dialog-title">Editar Usuário</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha os dados abaixo para editar o usuário.
            </DialogContentText>
            <Grid container spacing={1}>
              <Grid item xs>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                >
                  <InputLabel htmlFor="firstName">Nome</InputLabel>
                  <OutlinedInput
                    id="firstName"
                    name="firstName"
                    inputRef={register}
                    //onChange={handleChange}
                    //value={data.firstName}
                    //placeholder={userData.firstName}
                    defaultValue={userData.name.first}
                    labelWidth={35}
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item xs>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                >
                  <InputLabel htmlFor="lastName">Sobrenome</InputLabel>
                  <OutlinedInput
                    inputRef={register}
                    id="lastName"
                    name="lastName"
                    defaultValue={userData.name.last}
                    //onChange={handleChange}
                    //value={data.lastName}
                    labelWidth={70}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <InputLabel htmlFor="birthDate">Data de Nascimento</InputLabel>
              <OutlinedInput
                as={<OutlinedInput name="birthDate" startAdornment={
                  <InputAdornment position="start"> </InputAdornment>
                } />}
                id="birthDate"
                name="birthDate"
                control={control}
                onChange={(e) => setDate(e.target.value)}
                //defaultValue="2017-05-24"
                defaultValue={moment(userData.birthDate).format('YYYY-MM-DD')}
                //inputRef={register}
                type="date"
                labelWidth={125}

              // as={
              //   <OutlinedInput

              //     labelWidth={125}
              //   />
              //}
              />
            </FormControl>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <InputLabel htmlFor="email">Email</InputLabel>
              <OutlinedInput
                id="email"
                name="email"
                inputRef={register}
                defaultValue={userData.email}
                //onChange={handleChange}z
                //value={data.email}
                labelWidth={38}
                type="email"
              />
            </FormControl>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <InputLabel htmlFor="cpf">CPF</InputLabel>
              <OutlinedInput
                id="cpf"
                name="cpf"
                inputRef={register}
                defaultValue={userData.cpf}
                //onChange={handleChange}
                //value={data.cpf}
                labelWidth={38}
                type="cpf"
                inputComponent={cpfMask}
              />
            </FormControl>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <InputLabel htmlFor="phone">Telefone</InputLabel>
              <OutlinedInput
                id="phone"
                name="phone"
                inputRef={register}
                defaultValue={userData.phone}
                //onChange={handleChange}
                //value={data.phone}
                labelWidth={55}
                inputComponent={phoneMask}
              />
            </FormControl>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <InputLabel htmlFor="password">Senha</InputLabel>
              <OutlinedInput
                id="password"
                name="password"
                inputRef={register}
                defaultValue={userData.password}
                onChange={(e) => setPassword(e.target.value)}
                //value={data.phone}
                labelWidth={55}
              />
            </FormControl>
            {/* <FormGroup row>
              <Button
                className={classes.margin}
                variant="contained"
                color="primary"
                onClick={() => {
                  if (currentUserId !== user._id) {
                    confirm({
                      title: 'Remover usuário',
                      description: `Você tem certeza que deseja remover o usuário ${user.firstName} ${user.lastName}?`,
                      confirmationText: 'Sim',onChange
                      cancellationText: 'Não',
                    })
                      .then(() => {
                        handleUnLinkUser(user._id)
                      })
                      .catch(() => {
                        console.log('nao')
                      })
                  }
                  else {
                    confirm({
                      title: 'Ops!',
                      description: 'Vocẽ não pode se remover da loja',
                      confirmationText: 'OK!'
                    })
                  }
                }
                }
              >
                REMOVER DA LOJA
            </Button>
              <FormControlLabel
                control={
                  <Switch
                    checked={userData.isOnThisMonthGoal}
                    onChange={handleChangeSwitch}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={userData.isOnThisMonthGoal ? 'Remover da meta' : 'Incluir na meta'}
              />
            </FormGroup> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              {loading ? (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              ) : (
                  'Salvar'
                )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
